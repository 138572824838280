import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  position: fixed;
  width: 100%;
  bottom: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px 20px 30px;
  background: white;
  line-height: 1em;
  z-index: 1;
`;

const LinkButton = styled(Link)`
  font-size: 16px;
  font-weight: normal;
  color: #000000;
  letter-spacing: 2px;
  text-decoration: none;
`;

const Footer = () => (
  <StyledFooter>
    <LinkButton to="/contact">Contacto</LinkButton>
    <LinkButton to="/tienda">Tienda</LinkButton>
  </StyledFooter>
);

export default Footer;
