import { Link, navigate } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import breakpoints from '../styles/breakpoints';
import Logo from '../images/gramatos_logo.svg';
import Logo2 from '../images/logo_gramatos.svg';

const StyledHeader = styled.header`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background: white;

  grid-column: 1 / span 6;
  grid-row: 1 / 2;
  align-items: center;

  ${breakpoints.desktop} {
    grid-column: 2 / 14;
    grid-row: 1 / 2;
  }
`;

const SiteName = styled.h1`
  color: #000000;
  font-size: 16px;
  font-weight: normal;
  line-height: 1em;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 1.875em 0 1.25em;
`;

const LinkButton = styled(Link)`
  grid-column: 6 / 8;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: normal;
  color: #000000;
  letter-spacing: 2px;
  text-decoration: none;
`;

const GramatosLogo = styled.img.attrs(({ src }) => ({
  src,
}))`
  width: 0px;
  cursor: pointer;

  ${breakpoints.desktop} {
    width: 36px;
    justify-self: center;
  }
`;

const GramatosLogo2 = styled.img.attrs(({ src }) => ({
  src,
}))`
  width: 95px;
  cursor: pointer;

  ${breakpoints.desktop} {
    width: 110px;
  }
`;

const Header = ({ navigateTo = '/' }) => (
  <StyledHeader>
    <GramatosLogo src={Logo} onClick={() => navigate(navigateTo)} />
    <LinkButton to={navigateTo}>
      <SiteName>
        <GramatosLogo2 src={Logo2} onClick={() => navigate(navigateTo)} />
      </SiteName>
    </LinkButton>
    <span />
  </StyledHeader>
);

export default Header;
